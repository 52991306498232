import {Message} from "element-ui";

export function tableColumn(that) {
    return [
        {
            type: "index",
            label: "序号",
            width: 60,
        },
        {
            prop: "contractNum",
            label: "合同编号",
            width: 160,
            render: (h, {row}) => {
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click: () => that.getElectronicContract({...row}),
                    }
                }, row.contractNum);
            },
        },
        {
            prop: "contractStatusName",
            label: "合同状态",
            width: 100,
        },
        {
            prop: "contractDuration",
            label: "合同周期",
            width: 200,
        },
        {
            prop: "apartmentName",
            label: "房源名称",
            width: 250,
            render: (h, {row: {apartmentName}}) => {
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            that.$router.push({name: "house-resource", query: {data: {apartmerntName: apartmentName}}});
                        }
                    }
                }, apartmentName);
            },
        },
        {
            prop: "leasorName",
            label: "租户姓名",
            width: 100,
            render: (h, {row: {leasorName}}) => {
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click: () => that.$router.push({name: "tenant-management", params: {data: {leasorName}}}),
                    }
                }, leasorName);
            },
        },
        {
            prop: "leasorPhone",
            label: "手机号",
            width: 150,
        },
        {
            prop: "rentalBillStatusName",
            label: "房租账单状态",
        },
        {
            prop: "pledgeBillStatusName",
            label: "押金账单状态",
        },
        {
            prop: "bluePasswordSyncStatusName",
            label: "蓝牙同步状态",
        },
        {
            prop: "lockElect",
            label: "门锁电池",
        },
    ];
}
